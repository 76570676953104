.hh1 { display:inline-block; margin:0;text-transform:uppercase; }
.hh1:after {
  display:block;
  content: '';
  border-bottom: solid 1px #ffffff;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}
.hh1.fromLeft:after{ transform-origin: 100% 50%; }
.hh1.fromRight:after{  transform-origin:   0% 50%; }
.hh1.fromLeft:hover:after{ transform: scaleX(1); transform-origin:   0% 50%; }
.hh1.fromRight:hover:after{ transform: scaleX(1); transform-origin: 100% 50%; }




.headerbtn {
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  
  .headerbtn:hover {
    background-color: #5E10B1;
    box-shadow: 0px 15px 20px #5E10B1;
    color: #fff;
    transform: translateY(-7px);
  }
  
  .headerbtn:active {
    transform: translateY(-1px);
  }



  .obutton {
    margin-right: 20px;
    width: fit-content;
    min-width: 100px;
    height: 35px;
    border-radius: 5px;
    border: 2.5px solid #E0E1E4;
    box-shadow: 0px 0px 20px -20px;
    cursor: pointer;
    background-color: white;
    transition: all 0.2s ease-in-out 0ms;
    user-select: none;
    font-family: 'Poppins', sans-serif;
  }
  
  .obutton:hover {
    background-color: #F2F2F2;
    box-shadow: 0px 0px 20px -18px;
  }
  
  .obutton:active {
    transform: scale(0.95);
  }